import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

function TopicSelDocument({ fields }) {
  const [topicsLoad, setTopicsLoad] = useState([]);
  const [topics, setTopics] = useState([{ name: "", text: "" }]);

  useEffect(() => {
    var jsonMapTopic = [
      {
        name: "Gender and nutrition",
        value: "topicnutritiontext"
      },
      {
        name: "Gender and financial services",
        value: "topicfinancialtext",
      },
      {
        name: "Women's empowerment",
        value: "topicempowermenttext",
      },
      {
        name: "Temporary special measures",
        value: "topictemporarytext",
      },
      {
        name: "Gender and social protection",
        value: "topicsocialtext",
      },
      {
        name: "Gender and natural resources",
        value: "topicresourcestext",
      },
    ];

    fields.forEach((field) => {
      if (field.name === "topic") setTopicsLoad(field.textValues.values);
    });

    let topicss = [];
    for (let el of topicsLoad) {
      for (let jl of jsonMapTopic) {
        if (jl.name === el) {
          for (let fl of fields) {
            if (fl.name === jl.value) {
              topicss.push({
                name: el,
                text: fl.textValues.values,
              });
            }
          }
        }
      }
    }

    setTopics(topicss);
  }, [fields, topicsLoad]);

  return (
    <div className="topics">
      {topics ? topics.map((topic) =>
        <div className="topic-wrap" key={topic.name}>
          <div className="topic-name">
            <h4>{topic.name}:</h4>
          </div>
          <div className="topic-text">
            { topic.text && topic.text !== null && topic.text !== undefined
              ? topic.text.map((text) => (
                <div key={topic.name}>
                  {text}
                </div>
              ))
              : null}

          </div>
        </div>
      ) : null
      }
    </div>
  );
}

export default TopicSelDocument;
