import { Link, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import configPws from "../config";
import { QueryAdvSearch } from "./QueryAdvSearch";
import { QueryCountryDocumentsSearch } from "./QueryCountryDocumentsSearch";
import CircleGreen from "../images/circle-solid-green.svg";
import Config from "../config";
import ViewSpinner from "../shared/Spinner";
import { DownloadQueryTablePDF } from "./DownloadPDF";
import { DownloadXLS } from "./DownloadXLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";

let documentsss = [];

function AllDocumentsView() {
  const { countryId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    fetch(configPws.apis.gcs, {
      method: "POST",
      body: QueryCountryDocumentsSearch(countryId, page),
    })
      .then((response) => response.json())
      .then((data) => {
        let id = "";
        let typeOfText = "";
        let title = "";
        let date = "";
        let topicsArray = [];
        let titlePdf = "";

        for (let el of data.results) {
          titlePdf = el.title;
          topicsArray = [];
          for (let dc of el.metadata.fields) {
            switch (dc.name) {
              case "faolexId":
                id = dc.textValues.values[0];
                break;
              case "typeOfText":
                typeOfText = dc.textValues.values[0];
                break;
              case "titleOfText":
                title = dc.textValues.values[0];
                break;
              case "dateOfText":
                date = dc.textValues.values[0];
                break;
              case "topic":
                topicsArray.push(dc.textValues.values);
                break;
              case "country":
                if (dc.textValues.values.length > 1) {
                  setCountry((c) => c);
                } else {
                  setCountry(dc.textValues.values[0].split("#")[0]);
                }

                break;

              default:
            }
          }

          let documentview = {
            id: id,
            typeOfText: typeOfText,
            title: title,
            date: date,
            topicresources: false,
            topicempowerment: false,
            topicnutrition: false,
            topicsocial: false,
            topicfinancial: false,
            topictemporary: false,
            pdf: titlePdf,
          };

          for (let tpl of topicsArray[0] || topicsArray) {
            if (tpl === "Gender and natural resources") {
              documentview.topicresources = true;
            }
            if (tpl === "Women's empowerment") {
              documentview.topicempowerment = true;
            }
            if (tpl === "Gender and nutrition") {
              documentview.topicnutrition = true;
            }
            if (tpl === "Gender and social protection") {
              documentview.topicsocial = true;
            }
            if (tpl === "Gender and financial services") {
              documentview.topicfinancial = true;
            }
            if (tpl === "Temporary special measures") {
              documentview.topictemporary = true;
            }
          }

          documentsss.push(documentview);
        }

        if (!data.hasMoreResults) {
          setDocuments(documentsss);
        } else {
          setPage(page + 100);
        }
      })
      .then(() => setIsLoading(true));
  }, [countryId, page]);

  const DownloadPdf = () => {
    let rowInit = [
      [
        {
          text: "Rec.Id",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Type of text",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Title",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Date of text",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Gender and natural resources",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Women's empowerment",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Gender and nutrition",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Gender and social protection",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Gender and financial services",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
        {
          text: "Temporary special measures",
          style: "tableHeader",
          rowSpan: 0,
          alignment: "center",
        },
      ],
    ];

    documents.forEach((document) => {
      rowInit.push([
        {
          text: document.id,
          link: `${Config.urls.link_document}${document.id}`,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "blue",
        },
        {
          text: document.typeOfText.split("#")[0],
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
        },
        {
          text: document.title,
          link: `${Config.urls.link_pdf}${document.pdf}`,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "blue",
        },
        {
          text: document.date,
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
        },
        {
          text: document.topicresources ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicempowerment ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicnutrition ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicsocial ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topicfinancial ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
        {
          text: document.topictemporary ? "•" : "",
          style: "tableHeader",
          rowSpan: 1,
          alignment: "center",
          color: "green",
          fontSize: 22,
        },
      ]);
    });

    DownloadQueryTablePDF(rowInit, country);
  };

  const downloadXls = () => {
    let filesPdf = [];
    let formatXlsJson = [
      {
        id: "Rec.Id",
        typeOfText: "Type of text",
        title: "Title",
        date: "Date of text",
        topicresources: "Gender and natural resources",
        topicempowerment: "Women's empowerment",
        topicnutrition: "Gender and nutrition",
        topicsocial: "Gender and social protection",
        topicfinancial: "Gender and financial services",
        topictemporary: "Temporary special measures",
      },
    ];
    documents.forEach((data) => {
      filesPdf.push(data.pdf);
    });

    documents.forEach((data) => {
      formatXlsJson.push({
        id: data.id,
        typeOfText: data.typeOfText.split("#")[0],
        title: data.title,
        date: data.date,
        topicresources: data.topicresources ? "•" : "",
        topicempowerment: data.topicempowerment ? "•" : "",
        topicnutrition: data.topicnutrition ? "•" : "",
        topicsocial: data.topicsocial ? "•" : "",
        topicfinancial: data.topicfinancial ? "•" : "",
        topictemporary: data.topictemporary ? "•" : "",
      });
    });

    DownloadXLS(formatXlsJson, filesPdf, "Gender_lex_" + country);
  };

  return isLoading ? (
    <div>
      <div id="all-documents">
        <div className="header-wrap">
          <div className="country">
            <div className="img-country">
              <img
                className="country-page-flag"
                width="50px"
                alt=""
                src={`https://www.fao.org/fileadmin/assets/countries/flags/${
                  countryId && countryId.toString().toLowerCase()
                }.gif`}
              ></img>
            </div>
            <div className="name-country">
                <h3>{country}</h3>
              
            </div>
          </div>
          <div className="results-found">
            <h5>{documents ? documents.length : 0} Results</h5>
          </div>
          <div className="buttons-download">
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => DownloadPdf()}
            >
              Download PDF
              <FontAwesomeIcon
                icon={faFilePdf}
                color="red"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
            <button
              type="submit"
              className="btn btn-primary me-4"
              onClick={() => downloadXls()}
            >
              Download XLS
              <FontAwesomeIcon
                icon={faFileExcel}
                color="#90ee90"
                data-toggle="tooltip"
                data-placement="left"
                style={{ marginLeft: "5px" }}
              />
            </button>
          </div>
        </div>

        <table
          id="table-results"
          className="table"
          style={{ verticalAlign: "middle", fontSize: "13px" }}
        >
          <thead style={{ verticalAlign: "middle" }}>
            <tr>
              <th scope="col">Rec.Id</th>
              <th scope="col">Type of text</th>
              <th scope="col">Title</th>
              <th scope="col">Date of text</th>
              <th scope="col">Gender and natural resources</th>
              <th scope="col">Women's empowerment</th>
              <th scope="col">Gender and nutrition</th>
              <th scope="col">Gender and social protection</th>
              <th scope="col">Gender and financial services</th>
              <th scope="col">Temporary special measures</th>
            </tr>
          </thead>
          {documents
            ? documents.map((document) => (
                <tbody key={document.id}>
                  <tr>
                    <td style={{ width: "140px" }}><a href={"/search/document/"+document.id}>{document.id}</a></td>
                    <td>{document.typeOfText.split("#")[0]}</td>
                    <td>{document.title}</td>
                    <td style={{ width: "100px" }}>{document.date}</td>
                    <td>
                      {document.topicresources ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicempowerment ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicnutrition ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicsocial ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topicfinancial ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                    <td>
                      {document.topictemporary ? (
                        <img src={CircleGreen} alt="" />
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              ))
            : null}
        </table>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ViewSpinner />
    </div>
  );
}

export default AllDocumentsView;
