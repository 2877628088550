import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.min.css";
import { IntlProvider } from "react-intl";
import translations from "./i18n/locales";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FaoPageContainer from "./shared/FaoPageContainer";
import Home from "./pages/Home_page/Home";
import CountryProfiles from "./pages/CountryProfiles/CountryProfiles";
import AdvancedSearch from "./pages/AdvancedSearch/AdvancedSearch";
import Documents from "./pages/_Documents/Documents";
import AllDocumentsView from "./helper/AllDocumentsView";
import DocumentsDetail from "./pages/DocumentsDetail/DocumentsDetail";
import DocumentDetailSearch from "./pages/DocumentsDetailSearch/DocumentDetailSearch";

const App = ({ lang }) => {
  var url = window.location.pathname.replace(".html", "");
  var language = url.substring(url.length - 2);
  if (
    language !== "fr" &&
    language !== "es" &&
    language !== "ru" &&
    language !== "ar" &&
    language !== "zh"
  ) {
    language = "en";
  }

  return (
    <IntlProvider
      locale={language}
      key={language}
      defaultLocale="en"
      messages={translations[language]}
    >
      <Router>
        <Routes>
          <Route path="/" element={<FaoPageContainer />}>
            <Route path="" element={<Home />} />
            <Route path="country">
              <Route index={true} element={<CountryProfiles />} />
              <Route path="profiles/:countryId">
                <Route index={true} element={<Documents />} />
                <Route path="see-more/:subsectionTitle/:country">
                  <Route index={true} element={<DocumentsDetail />} />
                  <Route
                    index={false}
                    path="document/:faolexid"
                    element={<DocumentDetailSearch />}
                  />
                </Route>
                <Route
                  index={false}
                  path="document/:faolexid"
                  element={<DocumentDetailSearch />}
                />
              </Route>
            </Route>
            <Route
              path="country-snapshot/:countryId"
              element={<AllDocumentsView />}
            />
            <Route path="search">
              <Route index={true} element={<AdvancedSearch />} />
              <Route
                index={false}
                path=":topicSel"
                element={<AdvancedSearch />}
              />
              <Route
                index={false}
                path="document/:faolexid"
                element={<DocumentDetailSearch />}
              />
              <Route
                index={false}
                path="country/profiles/:countryId"
                element={<Documents />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </IntlProvider>
  );
};

export default App;
