import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Config from "../../config";
import { QueryDetailDocument } from "../../helper/QueryDetailDocument";
import ViewSpinner from "../../shared/Spinner";
import "./DocumentDetailSearch.css";
import PDFicon from "../../images/file-pdf-solid.svg";
import CountrySel from "../../helper/CountrySel";
import { Costants } from "../../helper/Costants";
import Moment from "moment";
import TopicSelDocument from "../../helper/TopicSelDocument";
import { QueryLexDocumentsSearch } from "../../helper/QueryLexDocumentsSearch";

function DocumentDetailSearch() {
  const { faolexid } = useParams();
  const [title, setTitle] = useState("");
  const [titlePDF, setTitlePDF] = useState("");
  const [text, setText] = useState([]);
  const [info, setInfo] = useState({});
  const [infoUnparse, setInfoUnparse] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsTopic, setFieldsTopic] = useState([]);

  useEffect(() => {
    setIsLoading(false);
    let infos = "";
    fetch(Config.apis.gcs, {
      method: "POST",
      body: QueryDetailDocument(faolexid),
    })
      .then((response) => response.json())
      .then((data) => {
        setFields(data.results[0].metadata.fields);
        var fields = data.results[0].metadata.fields;
        setTitlePDF(data.results[0].title);

        fields.forEach((field) => {
          if (field.name.includes("titleOfText")) {
            setTitle(field.textValues.values[0]);
          }
          if (field.name === "abstract") {
            setText(field.textValues.values[0].split("<br>"));
          }

          for (let el of Costants.tableInfoMap) {
            if (
              field.name !== "keyword" &&
              field.name !== "subjectSelectionEn" &&
              field.name !== "mainAreaEn"
            ) {
              if (field.name === el.field) {
                if (infos === "") {
                  infos += `"${el.field}": "${field.textValues.values[0]}",`;
                } else {
                  infos += `"${el.field}": "${field.textValues.values[0]}",`;
                }
              }
            } else {
              if (field.name === el.field) {
                let itemsArray = "";
                for (let fld of field.textValues.values) {
                  itemsArray += `"${fld}",`;
                }
                itemsArray = itemsArray.slice(0, -1);
                itemsArray = `[${itemsArray}]`;
                infos += `"${el.field}":${itemsArray}, `;
              }
            }
          }
        });
        setInfoUnparse(infos);
      })
      .then(() => {
        if (infoUnparse)
          setInfo(JSON.parse(`{${infoUnparse.trim().slice(0, -1)}}`));
      })
      .then(() => setTimeout(() => setIsLoading(true), 500));

    fetch(Config.apis.gcs, {
      method: "POST",
      body: QueryLexDocumentsSearch(faolexid),
    })
      .then((data) => data.json())
      .then((data) => {
        setFieldsTopic(data);
      });
  }, [faolexid, infoUnparse]);

  return (
    <div>
      {isLoading ? (
        <div id="documents-results-detail">
          <CountrySel fields={fields} />

          <div className="title-wrap">
            <div className="icon">
              <a
                href={`${Config.urls.link_pdf}${titlePDF}`}
                rel="noopener noreferrer"
                target="_blank"
                className="embed"
                title="View PDF"
              >
                <img src={PDFicon} alt="" />
              </a>
            </div>
            <div className="title">
              <h5>
                <a
                  href={`${Config.urls.faolex.details}${faolexid}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {title}
                </a>
              </h5>
            </div>
          </div>

          {fieldsTopic ? <div style={{ borderBottom: "2px solid #F2F2F2", marginBottom: '35px' }}>
            <TopicSelDocument fields={fieldsTopic.results[0].metadata.fields} />
          </div>: null }

          <div className="section-wrap" key={faolexid}>
            <div className="section-label">FAOLEX No.</div>
            <div className="section-text-sub">{faolexid}</div>
          </div>

          {Costants.tableInfoMap.map((field, i) =>
            info[field.field] &&
            field.field !== "keyword" &&
            field.field !== "faolexid" &&
            field.field !== "subjectSelectionEn" &&
            field.field !== "mainAreaEn" &&
            field.field !== "typeOfText" &&
            field.field !== "relatedWebSite" &&
            field.field !== "dateOfText" &&
            field.field !== "dateOfOriginalText" &&
            field.field !== "dateOfModification" &&
            field.field !== "dateOfConsolidation"
            ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <div className="section-text">{info[field.field]}</div>
              </div>
            ) : 
            info[field.field] && field.field === "keyword" ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <div className="section-text-keyword">
                  {info[field.field].map((keyw, index) =>
                    index === info[field.field].length - 1 ? (
                      <b key={index}>{keyw.split("#")[0]}</b>
                    ) : (
                      <b key={index}>{`${keyw.split("#")[0]}, `}</b>
                    )
                  )}
                </div>
              </div>
            ) : info[field.field] && field.field === "typeOfText" ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <div className="section-text">
                  {info[field.field].split("#")[0]}
                </div>
              </div>
            ) : info[field.field] && field.field === "relatedWebSite" ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <a
                  href={
                    info[field.field].includes("http")
                      ? info[field.field]
                      : `https://${info[field.field]}`
                  }
                  target="_blank"
                  className="section-text"
                  rel="noreferrer"
                >
                  {info[field.field]}
                </a>
              </div>
            ) : info[field.field] &&
              (field.field === "dateOfText" ||
                field.field === "dateOfOriginalText" || 
                field.field === "dateOfConsolidation" ) ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <div className="section-text">
                  {
                  <h6>{`${Moment(info[field.field]).format(
                    "DD MMMM YYYY"
                  )}`}</h6> 
                  }
                </div>
              </div>
            ) : info[field.field] &&
              (field.field === "subjectSelectionEn" ||
                field.field === "mainAreaEn") ? (
              <div className="section-wrap" key={i}>
                <div className="section-label">{field.name}</div>
                <div className="section-text-sub">
                  {info[field.field].map((keyw, index) =>
                    index === info[field.field].length - 1 ? (
                      <b key={index}>{keyw.split("#")[0]}</b>
                    ) : (
                      <b key={index}>{`${keyw.split("#")[0]}, `}</b>
                    )
                  )}
                </div>
              </div>
            ) : null
          )}

          <div style={{ borderBottom: "2px solid #F2F2F2", marginBottom: '35px' }}>
            <p style={{marginBottom: '35px' }}>
              Record updated on{" "}
              {Moment(info["dateOfModification"]).format("YYYY-DD-MM")}
            </p>
          </div>

          {text.map((p, i) => (
            <div className="text" key={i}>
              <p>{p}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="loading">
          <div className="wrap-spinner">
            {" "}
            <ViewSpinner />{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentDetailSearch;
