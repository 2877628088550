import { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import "./Documents.css";
import { AllDocumentsView } from "../../helper/AllDocumentsView";
import Pagination from "../../helper/Paginator/Pagination";
import Config from "../../config";
import { DownloadPDFSummary } from "../../helper/DownloadPDFSummary";

function Documents() {
  const { countryId } = useParams();
  const [profile, setProfile] = useState([]);
  const intl = useIntl();

  var language = window.location.pathname.substring(
    window.location.pathname.length - 2
  );

  var url = window.location.href;

  let dateLang = language;
  if (
    dateLang !== "fr" &&
    dateLang !== "es" &&
    dateLang !== "ru" &&
    dateLang !== "ar" &&
    dateLang !== "zh"
  ) {
    dateLang = "en";
  }

  language = dateLang;

  useEffect(() => {
    fetch(Config.apis.countryprofiles)
      .then((response) => response.json())
      .then((data) =>
        setProfile(
          data.profile.filter((x) => x.country_ISO3_Code === countryId)
        )
      );
  }, [countryId]);

  const DownloadPdfSummary = () => {
    let data = profile[0];

    // console.log(data.section, "data.section");

    let rowInit = [];

    let object = {};

    if (data.section !== null && data.section !== undefined) {
      let section = data.section;

      for (let i = 0; i < section.length; i++) {
        if (i === 0) {
          object = {
            text: section[i]["SectionTitle_" + language],
            style: "paragraph",
            alignment: "left",
            fontSize: 18,
            bold: true,
            color: "#116AAB",
          };
          rowInit.push(object);

          object = {
            text: section[i].document[0].Title_of_Text,
            link: `${Config.urls.faolex.details}${section[i].document[0].id}/`,
            decoration: "underline",
            style: "paragraph",
            alignment: "left",
            fontSize: 12,
            color: "#545454",
            marginTop: 8,
          };

          rowInit.push(object);

          object = {
            text: "Date of text: " + section[i].document[0].Date_of_Text,
            style: "paragraph",
            alignment: "left",
            fontSize: 12,
            color: "#545454",
          };

          rowInit.push(object);

          object = {
            text: "Non-discrimination and gender equality",
            style: "paragraph",
            alignment: "left",
            fontSize: 18,
            bold: true,
            color: "#116AAB",
            marginTop: 20,
          };
          rowInit.push(object);

          object = {
            text: section[i].document[0]
              .non_discrimination_and_gender_equality[0],
            style: "paragraph",
            alignment: "left",
            fontSize: 12,
            color: "#545454",
            marginTop: 8,
          };

          rowInit.push(object);
        } else if (i === 1) {
          object = {
            text: section[i]["SectionTitle_" + language],
            style: "paragraph",
            alignment: "left",
            fontSize: 18,
            bold: true,
            color: "#116AAB",
            marginTop: 20,
          };
          rowInit.push(object);

          if (section[i].document !== null) {
            let document = section[i].document;

            for (let y = 0; y < document.length; y++) {
              object = {
                text: document[y]["name_" + language],
                link: document[y].url,
                decoration: "underline",
                style: "paragraph",
                alignment: "left",
                fontSize: 12,
                color: "#116AAB",
                marginTop: 8,
              };

              rowInit.push(object);
            }
          }
        } else {
          object = {
            text: section[i]["SectionTitle_" + language],
            style: "paragraph",
            alignment: "left",
            fontSize: 18,
            color: "#116AAB",
            marginTop: 25,
          };
          rowInit.push(object);

          object = {
            text: section[i]["SectionDescription_" + language],
            style: "paragraph",
            alignment: "left",
            fontSize: 12,
            color: "#545454",
            marginTop: 8,
          };
          rowInit.push(object);

          if (section[i].document !== null) {
            let document = section[i].document;

            for (let y = 0; y < document.length; y++) {
              object = {
                text:
                  document[y].Title_of_Text + " " + document[y].Date_of_Text,
                link: `${url}/document/${document[y].faolex_id}/`,
                decoration: "underline",
                style: "paragraph",
                alignment: "left",
                fontSize: 12,
                color: "#116AAB",
                marginTop: 10,
              };

              rowInit.push(object);

              if (document[y].topic && document[y].topic !== null) {
                for (let z = 0; z < document[y].topic.length; z++) {
                  object = {
                    text: document[y].topic[z],
                    style: "paragraph",
                    alignment: "left",
                    fontSize: 12,
                    color: "#545454",
                    marginTop: 6,
                  };
                  rowInit.push(object);
                }
              }
            }
          }
        }
      }
    }

    let country = data["country_" + language].toString().toLowerCase();

    DownloadPDFSummary(rowInit, country);
  };

  return profile !== null
    ? profile.map((item) => (
        <div id="document-cp" key={item.country_ISO3_Code}>
          <div className="input-viewdoc">
            <Link
              to={{
                pathname: `/country-snapshot/${item.country_ISO3_Code}`,
              }}
              target="_blank"
              className="links"
            >
              <button
                type="submit"
                className="btn btn-primary btn-icon d-block mb-3 btn-lg"
                value={intl.formatMessage({ id: "Country_profiles.button" })}
              >
                <i className="bi bi-file-earmark-text"></i>
                <FormattedMessage id="Country_profiles.button" />
              </button>
            </Link>
          </div>

          <div className="input-viewdoc">
            <button
              type="submit"
              className="btn btn-primary btn-icon d-block mb-3 btn-lg"
              value={intl.formatMessage({ id: "Country_profiles.button" })}
              onClick={() => DownloadPdfSummary()}
            >
              <i className="bi bi-file-earmark-pdf"></i>
              Download PDF Summary
            </button>
          </div>

          {/* <div className="input-viewdoc">
            <a
              href={`${Config.urls.faolex.country_profiles}${countryId}`}
              target="_blank"
              className="links" rel="noreferrer"
            >
              <button
                type="submit"
                className="btn btn-primary btn-icon d-block mb-3 btn-lg"
                value={intl.formatMessage({ id: "Country_profiles.button" })}
              >
              <i className="bi bi-globe"></i>
              View Faolex Profile
              </button>
              </a>
          </div> */}

          <h2 className="page-title">
            <span
              className={`flag flag-${item.country_ISO3_Code
                .toString()
                .toLowerCase()}`}
            ></span>
            {item["country_" + language]}
          </h2>

          {item.section[0].document !== null
            ? item.section[0].document.map((doc) => (
                <div className="mt-5 mb-4" key={doc.Title_of_Text}>
                  <h5 className="title-link">
                    <a
                      href={`${Config.urls.faolex.details}${doc.id}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.Title_of_Text ? doc.Title_of_Text : ""}
                    </a>
                  </h5>
                  <FormattedMessage id="Country_profiles.country.dateoftext" />
                  {doc.Date_of_Text}
                  <br />
                  <br />
                  {doc.non_discrimination_and_gender_equality ? (
                    <div>
                      <h4 className="title-highlight">
                        Non-discrimination and gender equality
                      </h4>
                      <h5 className="sub-title small">
                        {doc.non_discrimination_and_gender_equality}
                      </h5>
                    </div>
                  ) : null}
                  <br />
                </div>
              ))
            : null}
          <div>
            <h4 className="country-profile-page-title">
              <div
                className="country-profiles-heading mb-3 mt-2"
                style={{ width: "100%" }}
              >
                <h4 className="title-highlight">
                  {item.section[1].SectionTitle_en}
                </h4>
              </div>
            </h4>
          </div>
          {item.section[1].document !== null
            ? item.section[1].document.map((doc) => (
                <div className="" key={doc.name_en}>
                  <a
                    href={
                      doc.url.includes("http") ? doc.url : `https://${doc.url}`
                    }
                    className="links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {doc.name_en}
                  </a>
                </div>
              ))
            : null}
          {item
            ? item.section.slice(2, item.section.length).map((desc) => (
                <div className="my-5" key={desc.SectionTitle_en}>
                  <h4 className="title-highlight">{desc.SectionTitle_en}</h4>
                  <h5 className="sub-title small">
                    {desc.SectionDescription_en}
                  </h5>
                  {desc.document !== null && desc.document !== undefined
                    ? desc.document.slice(0, 3).map((doc) => (
                        <div className="mt-5" key={doc.Title_of_Text}>
                          <Link to={`document/${doc.faolex_id}`}>
                            {doc.Title_of_Text ? doc.Title_of_Text : ""}
                          </Link>
                          {doc.Date_of_Text}
                          {doc.topic
                            ? doc.topic.map((t) => <p key={t}>{t}</p>)
                            : null}
                        </div>
                      ))
                    : null}
                  {desc.document !== undefined &&
                  desc.document !== null &&
                  desc.document.length > 3 ? (
                    <Link
                      to={{
                        pathname: `see-more/${desc.SectionTitle_en}/${
                          item["country_" + language]
                        }`,
                        country: item["country_" + language],
                        subsectionTitle: desc.SectionTitle_en,
                        countryId: countryId,
                      }}
                    >
                      <h5 className="title-link see-more text-color-un-blue">
                        <FormattedMessage id="Country_profiles.countryProfiles.seeMore" />
                        ...
                      </h5>
                    </Link>
                  ) : null}
                </div>
              ))
            : null}
        </div>
      ))
    : null;
}

export default Documents;
