import React, { useState, useEffect, useRef } from "react";
import "fao-design-system/fao-theme.min.css";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch, useParams, useLocation } from "react-router-dom";
import {
  faAlignJustify,
  faBars,
  faMars,
  faBookOpen,
  faGlobe,
  faSwatchbook,
  faSearch,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonNav from "./ButtonNav";
import XMark from '../images/xmark.svg'

const Sidenav = ({ margin, open }) => {
  const location = useLocation();

  var dateLang = window.location.pathname.substring(
    window.location.pathname.length - 2
  );
  if (
    dateLang !== "fr" &&
    dateLang !== "es" &&
    dateLang !== "ru" &&
    dateLang !== "ar" &&
    dateLang !== "zh"
  ) {
    dateLang = "en";
  }
  var lang = dateLang;

  function locationFrom(type) {
    if (location.state != null) {
      if (location.state.type === "country" && type === "country") {
        return true;
      } else if (location.state.type === "search" && type === "search") {
        return true;
      } else return false;
    }
  }

  const [isProfileCountryClicked, setProfileCountryClicked] = useState(
    locationFrom("/country")
  );
  const [isAdvSearchClicked, setIsAdvSearchClicked] = useState(
    locationFrom("/search")
  );

  if (
    window.location.pathname.includes("country") &&
    !isProfileCountryClicked
  ) {
    setProfileCountryClicked(true);
    setIsAdvSearchClicked(false);
  } else if (
    window.location.pathname.includes("search") &&
    !isAdvSearchClicked
  ) {
    setProfileCountryClicked(false);
    setIsAdvSearchClicked(true);
  }

  return (
    <div className={open ? "nav-container-close" : "nav-container-open"}>
      <button className="button-openside" onClick={margin}>
        {open ?  <i id="asideToggleIcon" className="bi bi-list fs-4"></i>
        : <i id="asideToggleIcon" className="bi bi-x fs-4"></i>}
      </button>
      <div className="divider-button"></div>
      <div className="button-nav-wrap">
        <ButtonNav
          icon={open ? "bi-house" : null}
          text={"Navigation.home"}
          className={"inside-button"}
          showText={!open}
          selected={true}
          link="/"
        />
        <ButtonNav
          icon={open ? "bi bi-globe" : null}
          text={"Navigation.country"}
          className={"inside-button"}
          showText={!open}
          selected={!isProfileCountryClicked}
          link="country"
        />
        <ButtonNav
          icon={open ? "bi bi-search": null}
          text={"Navigation.advanced"}
          className={"inside-button"}
          showText={!open}
          selected={!isAdvSearchClicked}
          link="search"
        />
      </div>
    </div>
  );
};

export default Sidenav;
