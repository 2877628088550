import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

function TopicSel({ fields, topicSelected }) {
  const [topicsLoad, setTopicsLoad] = useState([]);
  const [topics, setTopics] = useState([{ name: "", text: "" }]);
  const [topicSel, setTopicSel] = useState([]);

  useEffect(() => {
    var jsonMapTopic = [
      {
        name: "Gender and nutrition",
        value: "topicnutritiontext",
        selected: false,
      },
      {
        name: "Gender and financial services",
        value: "topicfinancialtext",
        selected: false,
      },
      {
        name: "Women's empowerment",
        value: "topicempowermenttext",
        selected: false,
      },
      {
        name: "Temporary special measures",
        value: "topictemporarytext",
        selected: false,
      },
      {
        name: "Gender and social protection",
        value: "topicsocialtext",
        selected: false,
      },
      {
        name: "Gender and natural resources",
        value: "topicresourcestext",
        selected: false,
      },
    ];

    const checkIfSelected = (topic) => {
      if (topicSelected.length === 0) return true;
      for (let el of topicSelected) {
        if (el === topic) return true;
      }

      return false;
    };

    fields.forEach((field) => {
      if (field.name === "topic") setTopicsLoad(field.textValues.values);
    });

    let topicss = [];
    for (let el of topicsLoad) {
      for (let jl of jsonMapTopic) {
        if (jl.name === el) {
          for (let fl of fields) {
            if (fl.name === jl.value) {
              topicss.push({
                name: el,
                text: fl.textValues.values,
                selected: checkIfSelected(el),
              });
            }
          }
        }
      }
    }

    let topicUnsel = [];
    for (let el of topicss) {
      for (let jl of jsonMapTopic) {
        if (jl.name === el.name) {
          if (!el.selected) {
            topicUnsel.push(el);
          }
        }
      }
    }

    setTopics(topicss);
    setTopicSel(topicUnsel);
  }, [fields, topicSelected, topicsLoad]);

  return (
    <div className="topics">
      {topics.map((topic) =>
        topic.selected ? (
          <div className="topic-wrap" key={topic.name}>
            <div className="topic-name">
              <h4>{topic.name}:</h4>
            </div>
            <div className="topic-text">
              <p>{topic.text}</p>
            </div>
          </div>
        ) : null
      )}{" "}
      {topicSelected ? (
        topicSelected.length !== 0 && topicSel.length !== 0 ? (
          <div className="topics">
            <Accordion defaultActiveKey="0">
              <Accordion.Item>
                <Accordion.Header>
                  <h5 className="about">Other Gender-Lex topics</h5>
                </Accordion.Header>
                <Accordion.Body className="topic-acc-body">
                  {topics.map((topic) =>
                    !topic.selected ? (
                      <div className="topic-wrap" key={topic.name}>
                        <div className="topic-name">
                          <h4>{topic.name}</h4>
                        </div>
                        <div className="topic-text">
                          <p>{topic.text}</p>
                        </div>
                      </div>
                    ) : null
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : null
      ) : null}
    </div>
  );
}

export default TopicSel;
